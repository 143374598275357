import React from "react";
import {
    Chip, Fade,
    Grid, LinearProgress,
    Paper, Stack, TextField,
    Tooltip
} from "@mui/material";
import {VariantType, WithSnackbarProps} from "notistack";
import {IBBCBridgeMessage, IDBNode, Util} from "dms_commons";
import {Column, Query, QueryResult} from "@material-table/core";
import DMSPersistentTable from "./DMSPersistentTable";

import Flag from 'react-world-flags';
import DMSHealthView from "./DMSHealthView";
import ModalDialog from "./ModalDialog";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import {ExportToCsv} from "../helpers/ExportToCsv";

interface IProps extends WithSnackbarProps {
    classes: any;
    dmsNodeBridgeMessagesQuery: (query: Query<IBBCBridgeMessage>) => Promise<QueryResult<IBBCBridgeMessage>>;
    logLine: (line: string) => void;
    displaySnackbar: (message: string, variant: VariantType) => void;
    exportDataRequested?: (fromDate: Date, toDate: Date, offset: number, limit: number) => Promise<IBBCBridgeMessage[]>;
    targetNode?: IDBNode;
}

interface IState {
    dmsNodeHealthColumns: Column<IBBCBridgeMessage>[];
    exportRequest?: {
        fromDate?: dayjs.Dayjs | null,
        toDate?: dayjs.Dayjs | null,
        batchBlockSize: 50,
        maxNumberOfBlocks: 10,
        isLoading?: boolean
    };
}

export default class DMSNodeHealthView extends React.Component<IProps, IState> {
    static refreshedTimestamp = new Date();

    public state: IState = {
        dmsNodeHealthColumns: [],
    };

    private tableKey = "node-health-table";

    public componentDidMount() {
        this.initDmsNodeHealthColumns();
    }

    public componentWillUnmount() {
        DMSNodeHealthView.refreshedTimestamp = new Date();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    }

    private initDmsNodeHealthColumns = () => {
        const dmsNodeHealthColumns: Column<IBBCBridgeMessage>[] = [
            {
                title: "State",
                render: (data: any) => {
                    const isError = data.state === "USER_ERROR" || data.state === "SYSTEM_ERROR";
                    return <Tooltip title={JSON.stringify(data, undefined, 4)}>
                        <Chip
                            style={{
                                backgroundColor: isError ? "rgba(119, 97, 64, 0.85)" : undefined
                            }}
                            avatar={<Flag
                                code={DMSHealthView.getLocaleFlag(data.locale)}
                                size={32}
                            />} size={"small"} label={data.state}/>
                    </Tooltip>;
                }
            },
            {
                title: "Headline",
                field: "headline"
            },
            {
                title: "Data",
                align: "center",
                render: data => {
                    return DMSHealthView.renderBridgeMessageData(data, false);
                }
            },
            {
                title: "Updated",
                field: "timestamp",
                type: "datetime",
                render: data => {
                    return <Tooltip title={Util.relativeDateTimeStringFromDBTimestamp(data.timestamp, true)}>
                        <p>{Util.relativeDateTimeStringFromDBTimestamp(data.timestamp)}</p>
                    </Tooltip>;
                }
            }
        ];

        this.setState({dmsNodeHealthColumns});
    };

    private tableRef = React.createRef<any>();

    private flattenObject = (obj: any) => {
        const flattened = {};

        Object.keys(obj).forEach((key) => {
            const value = obj[key];

            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                Object.assign(flattened, this.flattenObject(value));
            } else {
                flattened[key] = value;
            }
        });

        return flattened;
    };

    private initiateExport = async () => {
        const {exportRequest} = this.state;
        const {exportDataRequested, targetNode} = this.props;

        if (!exportDataRequested || !exportRequest) {
            return;
        }

        try {
            this.setState({
                exportRequest: {
                    ...exportRequest!,
                    isLoading: true
                }
            });

            let offset = 0;

            let aggregatedResults = new Array<IBBCBridgeMessage>();

            let res = await exportDataRequested(exportRequest.fromDate!.toDate(), exportRequest.toDate!.toDate(), offset, exportRequest.batchBlockSize!);
            aggregatedResults.push(...(res ?? []));

            let blocksReceived = 1;

            // while we keep getting data, and we have not exceeded the max number of blocks
            while ((res?.length ?? 0) > 0 && blocksReceived < exportRequest.maxNumberOfBlocks) {
                offset += exportRequest.batchBlockSize;
                res = await exportDataRequested(exportRequest.fromDate!.toDate(), exportRequest.toDate!.toDate(), offset, exportRequest.batchBlockSize!);
                aggregatedResults.push(...(res ?? []));
                blocksReceived++;
            }

            if (blocksReceived >= exportRequest.maxNumberOfBlocks) {
                this.props.displaySnackbar(`The number of requested rows cannot exceed ${exportRequest!.maxNumberOfBlocks * exportRequest.batchBlockSize}, try narrowing down your query`, "warning");
            }

            if (aggregatedResults.length > 0) {
                this.props.displaySnackbar(`Received ${aggregatedResults.length} rows from the database`, "info");
            } else {
                this.props.displaySnackbar("Received 0 rows from the database", "warning");
                return;
            }

            const filename = `${targetNode?.uid} (${exportRequest.fromDate?.toDate()?.toLocaleDateString()} - ${exportRequest.toDate?.toDate()?.toLocaleDateString()})`;

            const csvExporter = new ExportToCsv({
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                filename,
                title: targetNode?.uid + "@" + targetNode?.location,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            });

            // @ts-ignore
            aggregatedResults = aggregatedResults.map(obj => this.flattenObject(obj));

            // make sure all items have the exact same properties, even if they're empty
            let uniqueKeys = new Array<string>();

            aggregatedResults.forEach(r => {
                uniqueKeys.push(...Object.keys(r));
            });

            uniqueKeys = uniqueKeys.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });

            aggregatedResults.forEach(r => {
                for (const uniqueKey of uniqueKeys) {
                    if (!Object.hasOwn(r, uniqueKey)) {
                        r[uniqueKey] = "";
                    }
                }
            });

            csvExporter.generateCsv(aggregatedResults);
        } catch (e: any) {
            this.props.displaySnackbar(e?.toString(), "error");
        } finally {
            this.setState({
                exportRequest: {
                    ...exportRequest!,
                    isLoading: false
                }
            });
        }
    };

    public render() {
        const {dmsNodeBridgeMessagesQuery, exportDataRequested} = this.props;
        const {dmsNodeHealthColumns, exportRequest} = this.state;

        const canContinueExport = exportRequest !== undefined
            && exportRequest.fromDate !== undefined
            && exportRequest.toDate !== undefined;

        return <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ModalDialog
                    open={exportRequest !== undefined}
                    title={"Data Export"}
                    message={"Export bridge messages as a csv file."}
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    buttonOkTitle={"Export"}
                    buttonCancelDisabled={exportRequest?.isLoading}
                    buttonOkDisabled={!canContinueExport}
                    buttonOkIsLoading={exportRequest?.isLoading}
                    onOk={async () => {
                        await this.initiateExport();
                    }}
                    onCancel={() => {
                        this.setState({
                            exportRequest: undefined
                        });
                    }}>
                    <Stack>
                        <DesktopDatePicker
                            label={"Start Date"}
                            inputFormat="MM/DD/YYYY"
                            disableFuture={true}
                            onChange={(date) => {
                                this.setState({
                                    exportRequest: {
                                        ...this.state.exportRequest!,
                                        fromDate: date
                                    }
                                });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            value={exportRequest?.fromDate}/>
                        <DesktopDatePicker
                            label={"End Date"}
                            inputFormat="MM/DD/YYYY"
                            disableFuture={true}
                            onChange={(date) => {
                                this.setState({
                                    exportRequest: {
                                        ...this.state.exportRequest!,
                                        toDate: date
                                    }
                                });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            value={exportRequest?.toDate}/>
                        <TextField disabled={true} label={"Batch Block Size"} value={exportRequest?.batchBlockSize}/>
                        <TextField disabled={true} label={"Batch Block Limit"}
                                   value={exportRequest?.maxNumberOfBlocks}/>
                        <Fade style={{margin: 8}} in={exportRequest?.isLoading}>
                            <LinearProgress/>
                        </Fade>
                    </Stack>
                </ModalDialog>
            </LocalizationProvider>
            <DMSPersistentTable
                components={{
                    Container: props => <Paper elevation={0} {...props} />
                }}
                key={this.tableKey}
                tableKey={this.tableKey}
                tableRef={this.tableRef}
                columns={dmsNodeHealthColumns}
                data={dmsNodeBridgeMessagesQuery ?? []}
                options={{
                    showTitle: false,
                    draggable: false,
                    pageSize: 25,
                    header: true,
                    search: false,
                    sorting: false,
                    toolbar: true,
                    pageSizeOptions: [25, 50, 100],
                    loadingType: "overlay",
                    emptyRowsWhenPaging: false,
                    padding: "dense"
                }}
                actions={[
                    // @ts-ignore
                    (exportDataRequested ? {
                        icon: 'ios_share',
                        tooltip: 'Export',
                        isFreeAction: true,
                        onClick: () => {
                            this.setState({
                                exportRequest: {
                                    batchBlockSize: 50,
                                    maxNumberOfBlocks: 10,
                                    toDate: dayjs()
                                }
                            });
                        }
                    } : {}),
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                    }
                ]}
            />
        </Grid>;
    }
}

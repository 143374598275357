import React from "react";
import {
    Badge,
    Card,
    CardContent, Chip, CircularProgress,
    CssBaseline, Grid,
    Grow,
    LinearProgress, Slide,
    Tooltip,
    Typography,
    Zoom
} from "@mui/material";
import {
    ABDState, DMSRESTApiClient,
    IBBCBridgeBagomatMessage,
    IBBCBridgeTagomatMessage,
    IDBNode, IDBUser,
    IDMSNode,
    IDMSServerStats,
    IPortForwardingRule,
    TGMState, Util
} from "dms_commons";
import {Masonry} from "@mui/lab";
import {Print, Warning} from "@mui/icons-material";
import DMSUserView from "./DMSUserView";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalStorageHelper from "../helpers/LocalStorageHelper";

const combineLocations = [{
    src: "xflytoget-",
    dst: "flytoget"
}];

const dcsBaseUrl = "https://dcscloud.bbcairport.com/";

type DcsService = { url: string, isOk?: boolean, isLoading?: boolean, error?: Error }

const dcsServices = [
    {url: "sbd/afkl"},
    {url: "tgm/afkl"},
    {url: "sbd/dy"},
    {url: "tgm/dy"},
    {url: "tgm/iport"},
    {url: "tgm/sas"},
    {url: "device-gateway"},
    {url: "sbd/icelandair"},
    {url: "tgm/icelandair"},
    {url: "tgm/ink"},
    {url: "sbd/thomascook"},
    {url: "tgm/thomascook"},
    {url: "sbd/lufthansa-group"},
    {url: "tgm/lufthansa-group"},
    {url: "sbd/wideroe"},
    {url: "tgm/wideroe"},
    {url: "sbd/aegean"},
    {url: "tgm/aegean"},
    {url: "sbd/lot"},
    {url: "tgm/lot"},
    {url: "sbd/wgh"},
    {url: "tgm/wgh"},
    {url: "sbd/atlantic"},
    {url: "tgm/atlantic"},
    {url: "tgm/play"},
    {url: "sbd/egyptair"},
    {url: "tgm/egyptair"},
    {url: "tgm/tui"},
    {url: "sbd/navitaire"},
    {url: "tgm/navitaire"},
] as DcsService[];

dcsServices.forEach(s => {
    s.isLoading = true;
});

interface IProps {
    classes: any;
    dmsNodes: Map<string, IDBNode>;
    dmsUsers: IDBUser[];
    dmsOnlineNodes: Map<string, IDMSNode>;
    portRules: IPortForwardingRule[];
    serverStats?: IDMSServerStats;
    isLoadingNodes: boolean;
    displaySnackbar: (message: string, variant) => void;
    logItems: string[];
    dmsRestClient: DMSRESTApiClient;
    onLoadDataRequested: () => void;
}

interface IState {
    nodeLocations: Record<string, IDBNode[]>,
    uniqueLocations: Array<{ name: string, nodeCount: number }>,
    ready: boolean
}

export default class DMSDashView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            nodeLocations: {},
            uniqueLocations: [],
            ready: false
        };

        this.performDcsHealthcheck();
    }

    private getJwtToken = () => {
        return LocalStorageHelper.getAuthToken();
    };

    private performDcsHealthcheck = async () => {
        const healthcheckPromises = new Array<() => Promise<void>>();

        const jwtToken = this.getJwtToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        for (const s of dcsServices) {
            healthcheckPromises.push(() => {
                return new Promise<void>(async resolve => {
                    s.isLoading = true;

                    let isOk = false;

                    try {

                        const url = dcsBaseUrl + s.url;

                        await Util.sleep(250);

                        const response = await this.props.dmsRestClient.postPerformHealthcheck(jwtToken, url);

                        isOk = response === 200;

                        if (!isOk) {
                            throw new Error("unexpected code: " + response);
                        }
                    } catch (e) {
                        s.error = e as any;
                    } finally {
                        s.isLoading = false;
                        s.isOk = isOk;

                        this.forceUpdate();
                        resolve();
                    }
                });
            });
        }

        await Util.promiseAllConcurrent(2, this.shuffle(healthcheckPromises));

        setTimeout(this.performDcsHealthcheck, 120 * 1000);
    };

    private shuffle = (array: Array<any>) => {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    };

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (Object.keys(prevProps.dmsNodes).length !== Object.keys(this.props.dmsNodes).length
            || Object.keys(prevProps.dmsOnlineNodes).length !== Object.keys(this.props.dmsOnlineNodes).length) {

            this.props.displaySnackbar("Node list updated", "info");

            const uniqueLocations = new Array<{ name: string, nodeCount: number }>();
            const nodeLocations: Record<string, IDBNode[]> = {};

            Object.keys(this.props.dmsNodes).forEach(key => {
                const n = this.props.dmsNodes[key] as IDBNode;

                let nodeLocation = n.location as string;

                for (const combineLocation of combineLocations) {
                    if (nodeLocation.indexOf(combineLocation.src) > -1) {
                        nodeLocation = combineLocation.dst;
                        break;
                    }
                }

                const targetIndex = uniqueLocations.findIndex(v => v.name === nodeLocation);

                if (targetIndex < 0) {
                    uniqueLocations.push({
                        name: nodeLocation,
                        nodeCount: 0
                    });
                } else {
                    uniqueLocations[targetIndex].nodeCount++;
                }

                if (!nodeLocations[nodeLocation]) {
                    nodeLocations[nodeLocation] = [];
                }

                nodeLocations[nodeLocation].push(n);
            });

            uniqueLocations.sort((a, b) => {
                return b.nodeCount - a.nodeCount;
            });

            this.setState({
                nodeLocations,
                uniqueLocations,
                ready: true
            }, () => {

                if (Object.keys(this.props.dmsOnlineNodes).length < 1) {
                    window.location.reload();
                }
            });
        }
    }

    public render() {
        const {classes, isLoadingNodes} = this.props;

        if (isLoadingNodes) {
            return this.loadingView();
        }

        return <Grid container spacing={2} style={{padding: 8}}>
            <CssBaseline/>

            <div style={{position: "fixed", bottom: 24, right: 8, opacity: 0.25, zIndex: -100}}>
                <img alt={"logo"} src={"bbclogo.png"}/>
            </div>

            <Grid item xs={12}>
                <Zoom in={this.state.ready} key={"users"}>
                    <Card>
                        <CardContent>
                            <Typography textAlign="left" variant="h5">{"Users"}</Typography>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    gap: 8,
                                    paddingTop: 8
                                }}>
                                {
                                    Object.values(this.props.dmsUsers).filter(u => {
                                        return this.props.dmsOnlineNodes[u.username] !== undefined;
                                    }).map(value => {
                                        return <DMSUserView
                                            key={value.username}
                                            classes={classes}
                                            isUserOnline={username => true}
                                            username={value.username}/>;
                                    })
                                }
                            </div>
                        </CardContent>
                    </Card>
                </Zoom>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography textAlign="left" variant="h5">{"DCS Services"}</Typography>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: 8,
                                paddingTop: 8
                            }}>
                            {
                                dcsServices.map(s => {
                                    return <Tooltip title={s.error ? s.error.toString() : "OK"} key={s.url}>
                                        <Chip
                                            icon={s.isLoading ? <CircularProgress size={24}/> : s.isOk ?
                                                <CheckCircleIcon
                                                    sx={{"&&": {color: "rgb(81, 176, 51)"}}}/> :
                                                <Warning
                                                    sx={{"&&": {color: "rgba(239,146,2,0.85)"}}}/>}
                                            label={s.url}/>
                                    </Tooltip>;
                                })
                            }
                        </div>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Masonry columns={{xs: 3, sm: 4, md: 6, lg: 12}} spacing={2}>
                    {
                        Object.values(this.state.uniqueLocations).map((location, i) => {
                            return this.renderLocationInfo(location.name, i);
                        })
                    }
                </Masonry>
            </Grid>

            {/*<Console
                style={{position: "absolute", left: 0, bottom: 0, right: 0, height: 120}}
                classes={classes}
                logItems={this.props.logItems}/>*/}

        </Grid>;
    }

    private renderLocationInfo = (location: string, index: number) => {
        const {nodeLocations} = this.state;
        const nodes = nodeLocations[location];

        const onlineNodes = nodes.filter(n => this.props.dmsOnlineNodes[n.uid] !== undefined);
        const nodesOnlinePercentage = 100 / nodes.length * onlineNodes.length;

        const nodesOnlinePercentageThreshold = 40;

        const nodesInError = onlineNodes.filter(n => {
            const nodeLastState = (n.lastBridgeMessage as IBBCBridgeTagomatMessage | IBBCBridgeBagomatMessage)?.state;

            return nodeLastState === ABDState.OUT_OF_ORDER || nodeLastState === TGMState.SYSTEM_ERROR /*|| nodeLastState === TGMState.USER_ERROR*/;
        });

        const nodesPrinting = onlineNodes.filter(n => {
            const nodeLastState = (n.lastBridgeMessage as IBBCBridgeTagomatMessage)?.state;

            return nodeLastState === TGMState.PRINTING;
        });

        const nodesCommunicating = onlineNodes.filter(n => {
            const model = (n.lastBridgeMessage as IBBCBridgeTagomatMessage)?.model;

            return model?.flight !== undefined;
        });

        return <Zoom key={location} in={true} style={{transitionDelay: (index * 20) + "ms"}}>
            <Card variant={"elevation"}>
                <CardContent>
                    <Typography textAlign="left" variant="h5">{location}</Typography>
                    <LinearProgress
                        color={nodesOnlinePercentage > nodesOnlinePercentageThreshold ? "primary" : "warning"}
                        style={{marginTop: 8, marginBottom: 8}}
                        variant={"determinate"} value={nodesOnlinePercentage}/>
                    <Typography textAlign={"center"} variant="body2"
                                color="text.secondary">{onlineNodes.length + "/" + nodes.length + " Online"}</Typography>


                    <div style={{
                        marginTop: 8,
                        minHeight: 32,
                        display: "flex",
                        alignItems: "center",
                        columnGap: 8,
                        flexWrap: "wrap"
                    }}>
                        <Grow in={nodesInError?.length > 0} unmountOnExit={true}>
                            <Tooltip title={nodesInError.map(n => n.uid).join("\r\n")}>
                                <Badge badgeContent={nodesInError.length} color={"warning"}>
                                    <Warning/>
                                </Badge>
                            </Tooltip>
                        </Grow>

                        <Grow in={nodesPrinting?.length > 0} unmountOnExit={true}>
                            <Tooltip title={nodesPrinting.map(n => n.uid).join("\r\n")}>
                                <Badge badgeContent={nodesPrinting.length} color={"primary"}>
                                    <Print/>
                                </Badge>
                            </Tooltip>
                        </Grow>

                        {nodesCommunicating.map(n => {
                            if ((n.lastBridgeMessage as any).model?.flight?.airline) {
                                return <Slide in={true} direction={"right"} unmountOnExit={true}>
                                    <Tooltip title={n.uid}>
                                        <img alt={"airline"} style={{height: 24, margin: 4}}
                                             src={"/airlines/" + (n.lastBridgeMessage as any)?.model?.flight?.airline + ".png"}/>
                                    </Tooltip>
                                </Slide>;
                            } else {
                                return undefined;
                            }
                        })}
                    </div>
                </CardContent>
            </Card>
        </Zoom>;
    };

    private loadingView = () => {
        const {} = this.props;

        return <div style={{
            height: "100%",
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center"
        }}>
            <CssBaseline/>
            <div style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <LinearProgress color={"primary"} variant={"indeterminate"}
                                style={{margin: 8, width: "50%"}}/>
            </div>
        </div>;
    };
}
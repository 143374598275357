import MaterialTable, {MaterialTableProps} from "@material-table/core";
import React, {Component} from "react";

interface IProps<RowData extends object> extends MaterialTableProps<RowData> {
    tableKey: string;
}

interface IState {

}

export default class DMSPersistentTable<RowData extends object> extends Component<IProps<RowData>, IState> {
    public static getDefaultFilter = (tableKey: string, itemKey: string) => {
        const data = localStorage.getItem(`filtering.${tableKey}.${itemKey}`);

        if (data) {
            try {
                const res = JSON.parse(data);

                if (res?.length === 0) {
                    return undefined;
                } else {
                    return res;
                }
            } catch (e) {

            }
        }

        return undefined;
    };

    public render() {
        return <MaterialTable
            onFilterChange={filters => {
                filters.forEach(filter => {
                    if (filter.column.filtering && filter.column.field) {
                        const serializedData = JSON.stringify(filter.value);
                        localStorage.setItem("filtering." + this.props.tableKey + "." + filter.column.field.toString(), serializedData);
                    }
                });
            }} {...this.props}/>;
    }
}
import {VIEW_TYPE} from "../enums/VIEW_TYPE";
import {IVersion} from "dms_commons";

const TOKEN_KEY = "token";
const CURRENT_VIEW_KEY = "current_view";
const CONSOLE_ENABLED_KEY = "console_enabled";
const LAST_VERSION_KEY = "installed_version";
const SERVER_STATS_EXPANDED = "server_stats_expanded";

export default class LocalStorageHelper {
    public static getAuthToken(): string | null {
        return localStorage.getItem(TOKEN_KEY);
    }

    public static setAuthToken(token: string | null) {
        if (!token) {
            localStorage.removeItem(TOKEN_KEY);
            return;
        }

        localStorage.setItem(TOKEN_KEY, token);
    }

    public static getCurrentView(): VIEW_TYPE {
        const selectedView = localStorage.getItem(CURRENT_VIEW_KEY);

        const n = Number(selectedView);

        return n as VIEW_TYPE;
    }

    public static setCurrentView(currentView: VIEW_TYPE | null) {
        if (currentView === null) {
            localStorage.removeItem(CURRENT_VIEW_KEY)
            return;
        }

        localStorage.setItem(CURRENT_VIEW_KEY, currentView.toString());
    }

    public static getServerStatsExpanded(): boolean {
        const serverStatsExpanded = localStorage.getItem(SERVER_STATS_EXPANDED);

        return serverStatsExpanded === "true";
    }

    public static setServerStatsExpanded(enabled: boolean) {
        localStorage.setItem(SERVER_STATS_EXPANDED, enabled.toString());
    }

    public static getConsoleEnabled(): boolean {
        const consoleEnabled = localStorage.getItem(CONSOLE_ENABLED_KEY);

        return consoleEnabled === "true";
    }

    public static setConsoleEnabled(enabled: boolean) {
        localStorage.setItem(CONSOLE_ENABLED_KEY, enabled.toString())
    }

    public static getLastVersion(): IVersion | undefined {
        const lastVersionJson = localStorage.getItem(LAST_VERSION_KEY);

        if (lastVersionJson) {
            return JSON.parse(lastVersionJson) as IVersion;
        }
    }

    public static setLastVersion(version: IVersion) {
        localStorage.setItem(LAST_VERSION_KEY, JSON.stringify(version));
    }
}

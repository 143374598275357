import React from "react";
import App from "../App/App";
import {VIEW_TYPE} from "../../enums/VIEW_TYPE";
import {appTheme} from "../App/Styles";
import {ThemeProvider} from '@mui/material/styles';
import RouteComponentProps from "../../helpers/RouteComponentProps";
import withRouter from "../../helpers/withRouter";

interface IProps extends RouteComponentProps<{ username: string, viewerToken: string }> {
    classes: any;
}

interface IState {
}

class Dash extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    public async componentDidMount() {
    }

    public render() {
        return (<ThemeProvider theme={appTheme}>
            {
                // @ts-ignore
                <App selectedView={VIEW_TYPE.VIEW_DASH}/>
            }
        </ThemeProvider>);
    }
}

export default withRouter(Dash);

import React, {ReactNode} from "react";
import {
    AppBar,
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    Slide,
    Toolbar,
    Typography
} from "@mui/material";
import CustomButton from "./CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";

interface IProps {
    children?: ReactNode;
    open: boolean;
    title: string;
    subtitle?: string;
    titleComponent?: any;
    message?: string;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    buttonOkTitle?: string;
    buttonOkComponent?: any;
    buttonCancelTitle?: string;
    buttonOkDisabled?: boolean;
    buttonCancelDisabled?: boolean;
    buttonOkIsLoading?: boolean;
    onOk?: () => void;
    onCancel: () => void;
    disableEscapeKeyDown?: boolean;
    disableBackdropClick?: boolean;
    fullScreen?: boolean;
    hideCancelButton?: boolean;
    hideOkButton?: boolean;
}

// tslint:disable-next-line:no-empty-interface
interface IState {

}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class ModalDialog extends React.Component<IProps, IState> {
    public state: IState = {
        open: this.props.open
    };

    public render() {
        const {fullScreen, open} = this.props;

        if (!open) {
            return null;
        }

        return (
            <div>
                <Dialog
                    scroll={"body"}
                    PaperProps={{
                        elevation: 0
                    }}
                    disableEscapeKeyDown={this.props.disableEscapeKeyDown !== undefined ? this.props.disableEscapeKeyDown : true}
                    //onBackdropClick={this.props.disableBackdropClick !== undefined ? this.props.disableBackdropClick : true}
                    open={this.props.open}
                    keepMounted={false}
                    TransitionComponent={Transition}
                    onClose={this.props.onCancel}
                    fullWidth={true}
                    fullScreen={this.props.fullScreen}
                    maxWidth={this.props.maxWidth}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {fullScreen ? undefined : this.props.titleComponent
                        ? <DialogTitle id="alert-dialog-slide-title">{this.props.titleComponent}</DialogTitle>
                        : <DialogTitle id="alert-dialog-slide-title">{this.props.title}</DialogTitle>
                    }
                    {this.props.subtitle ?
                        <DialogTitle id="alert-dialog-slide-description">{this.props.subtitle}</DialogTitle> : undefined
                    }
                    <DialogContent style={{padding: fullScreen ? 0 : undefined, height: "100%"}}>
                        {fullScreen ? <AppBar style={{
                            background: "rgb(51,51,51)",
                            color: "white"
                        }}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={this.props.onCancel}
                                            aria-label="close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography style={{flex: 1}} variant="h6">
                                    {this.props.title}
                                </Typography>
                                {this.props.hideOkButton ? undefined :
                                    this.props.buttonOkComponent ??
                                    <Button autoFocus color="inherit" onClick={this.props.onOk}>
                                        {this.props.buttonOkTitle ?? "Ok"}
                                    </Button>
                                }
                            </Toolbar>
                        </AppBar> : undefined}
                        {this.props.message ? <DialogContentText id="alert-dialog-slide-description">
                            {this.props.message}
                        </DialogContentText> : undefined}
                        {this.props.open ?
                            <div style={{
                                height: "100%",
                                width: "100%",
                                margin: fullScreen ? 0 : undefined,
                                marginTop: fullScreen ? 62 : undefined
                            }}>{this.props.children}</div> : undefined}
                    </DialogContent>
                    {!fullScreen ?
                        <DialogActions>
                            {!this.props.hideCancelButton ?
                                <CustomButton color="primary" onClick={this.props.onCancel}
                                              disabled={this.props.buttonCancelDisabled}>
                                    {this.props.buttonCancelTitle ?? "Cancel"}
                                </CustomButton> : undefined}
                            {!this.props.hideOkButton ?
                                <CustomButton isLoading={this.props.buttonOkIsLoading} color="primary"
                                              disabled={this.props.buttonOkDisabled}
                                              onClick={this.props.onOk}>
                                    {this.props.buttonOkTitle ?? "Ok"}
                                </CustomButton> : undefined}
                        </DialogActions> : undefined}
                </Dialog>
            </div>
        );
    }
}